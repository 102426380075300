import React from "react";

import highFiveImage from "@images/high-five-hands.png";
import calculatorImage from "@images/calculator.png";
import blurredWomanImage from "@images/blurred-woman.png";
import cogsImage from "@images/cogs.png";

const Slogan = () => {
  const grayscale = "100%";

  return (
    <section className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-highlight tracking-wide uppercase">
            Gifting | Cashback | Loyalty
          </h2>
          <p className="mt-1 text-4xl font-extrabold text-primary sm:text-5xl sm:tracking-tight lg:text-6xl">
            Create shopper traffic.
          </p>
        </div>
          <ul className="pt-12 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 xl:gap-x-8">
            {/* <!-- Current: "ring-2 ring-offset-2 ring-indigo-500", Default: "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" --> */}
            <Li title="Acquire New Customers" image={highFiveImage} grayscale={grayscale} />
            <Li title="Increase Revenues" image={calculatorImage} grayscale={grayscale} />
            <Li title="Retain Existing Customers" image={blurredWomanImage} grayscale={grayscale} />
            <Li title="Efficient Payments" image={cogsImage} grayscale={grayscale} />
          </ul>
      </div>
    </section>
);
}

const Li = ({ title, image, grayscale }) => (
  <li className="relative">
    <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
      <img src={image} alt="" style={{ filter: `grayscale(${grayscale})` }} className="opacity-80 group-hover:opacity-70 object-cover pointer-events-none"/>
      <div aria-hidden="true" className="absolete inset-0 bg-highlight bg-opacity-30"></div>
      <button type="button" className="absolute inset-0">
        <span className="sr-only">{title}</span>
      </button>
    </div>
    <p className="mt-2 block capitalize text-center text-xl font-medium text-gray-500 pointer-events-none">{title}</p>
    {/* <p className="block text-sm font-medium text-gray-500 pointer-events-none">3.9 MB</p> */}
  </li>
);

export default Slogan;

